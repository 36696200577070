import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import myHealthFirst from 'bookings/my_health_first.js';
import {ButtonV2} from '@HealthShareAU/hs-component-library';

import {
    BOOK_NOW_BTN_MH1,
    BOOK_NOW_BTN_MH1_PRIMARY,
    BOOK_NOW_BTN_MH1_PRIMARY_DISABLED,
} from 'core/constants.js';
import {BookingButton} from '@HealthShareAU/hs-component-library';

const MH1BookNowBtn = ({
    bookingIntegration,
    colorTheme,
    gaSelector,
    gaObjectType,
    availability,
    isMh1Running = true,
    dataSource,
}) => {
    useEffect(() => {
        myHealthFirst.widgetSetup();
    }, [isMh1Running]);

    if (!bookingIntegration) {
        return null;
    }

    return isMh1Running ? (
        <div className="mh1-book-now-button">
            <BookingButton
                availability={availability}
                customClass="1stAvailableWidget2"
                colorTheme={colorTheme}
                id={bookingIntegration.bookingId}
                dataSource={dataSource?.source}
                dataTestId={BOOK_NOW_BTN_MH1}
                gaSelector={
                    gaSelector || availability
                        ? BOOK_NOW_BTN_MH1_PRIMARY
                        : BOOK_NOW_BTN_MH1_PRIMARY_DISABLED
                }
                gaObjectType={gaObjectType}
                icon="calendarCheck"
                text="Book now"
            />
        </div>
    ) : (
        <ButtonV2
            customClass="1stAvailableWidget2"
            colorTheme={colorTheme}
            id={bookingIntegration.bookingId}
            dataSource={dataSource?.source}
            dataTestId={BOOK_NOW_BTN_MH1}
            gaSelector={gaSelector || BOOK_NOW_BTN_MH1_PRIMARY}
            gaObjectType={gaObjectType}
            icon="calendarCheck"
            text="Book now"
        />
    );
};

MH1BookNowBtn.propTypes = {
    bookingIntegration: PropTypes.shape({
        bookingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
    }).isRequired,
    colorTheme: PropTypes.object,
    gaSelector: PropTypes.string,
    gaObjectType: PropTypes.string,
    availability: PropTypes.object,
};
export default MH1BookNowBtn;
